import './App.css';
import LandingPage from './landingpage/LandingPage';

function App() {
  return (
    <LandingPage />
  );
}

export default App;
