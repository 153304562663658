import React, { Component } from 'react';
import { TextField, Button, Card, CardContent, CardHeader } from '@material-ui/core';
import './LandingPage.css';

export default class LandingPage extends Component {
    render() {
			 return <SearchBar /> 
    }
}

class SearchBar extends Component<{}, {epj :string, url:string, showForm : boolean, data:any}> {
	
	constructor(props: any){
			super(props);
			this.state = {epj:'', url: '', showForm  : false, data : null};
			this.handleChangeEpj = this.handleChangeEpj.bind(this);
			this.handleChangeUrl = this.handleChangeUrl.bind(this);
   		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChangeEpj(event: any) {
    	this.setState({epj: event.target.value, url : this.state.url});
	 }
	 
	 handleChangeUrl(event: any) {
    	this.setState({epj: this.state.epj, url : event.target.value});
 	}

	handleSubmit(event: any) {
		this.setState({showForm : false});
		const url  = this.state.url === '' ? null : this.state.url; 
		fetch("/sitefactory/landingpage/get/" + this.state.epj + "/" + url)
            .then(response => response.json())
            .then((data) => {
							if(data === null || data.length === 0){
								this.setState({showForm : false});
								alert('Aucune landing page correspond aux critéres saisis! Merci de modifier votre recherche ou de contacter un administrateur.');
							} else if(data.length >1){
								this.setState({showForm : false});
								alert('Plusieurs landing page correspondent à vos critéres, Merci d\'affiner la recherche ou de contacter un administrateur.');
							} else if(data.length ===1){
								this.setState({showForm : true, data: data[0]});
							}
						})
						.catch(console.log);
	}

	render() {
    return (

			<div >
				<form >
						<Card  className ="marginBottom gridWidth" >
							<CardHeader title="Recherche : " className="gridHeaderText">
							</CardHeader>
							<CardContent>
								
								<TextField label="Epj" value={this.state.epj} onChange={this.handleChangeEpj} className="inputClass" required/>
								<TextField label="Url"  value={this.state.url} onChange={this.handleChangeUrl} className="inputClass"/>
								<Button variant="contained" color="secondary" onClick={this.handleSubmit} disabled={!this.state.epj} className="align">
									Rechercher
								</Button>
							</CardContent>
						</Card>
				</form>
				
				{this.state.showForm && <LandingPageForm value={this.state.data}/>}
			</div>
    )
  }
}

class LandingPageForm extends Component<{value : any}, {data : any}> {
	
	constructor(props: any){
		super(props);
		this.state = {data : props.value};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event: any) {
		const { data } = { ...this.state };
  	const currentState = data;
  	const { name, value } = event.target;
  	currentState[name] = value;

  	this.setState({ data: currentState });
	}

	

	handleSubmit(){
		console.log(this.state.data);
		const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(this.state.data)
    };
    fetch('/sitefactory/landingpage/publish', requestOptions)
				.then(data => {
					alert("La demande de publication est envoyée, elle sera prise en compte dans quelques instants!");
					//this.props.addToast('La demande de publication est envoyée, elle sera prise en compte dans quelques instants!', { appearance: 'success' });
				})
				.catch(function(error) {
					console.log(error);
					alert("Un probléme est survenu à la publication! Merci de réessayer plus tard ou contacter l'administrateur.");
					//this.props.addToast('Un probléme est survenu à la publication! Merci de réessayer plus tard ou contacter l\'administrateur.', { appearance: 'error' });
				});
	}

	render(){
		return (
						<Card  className ="marginBottom gridWidth" >
							<CardHeader title="Résultat : " className="gridHeaderText">
							</CardHeader>
							<div className="grid marginBottom" >
								<TextField multiline rows={4}  name="headline" label="Titre principal"  value={this.state.data.headline} onChange={this.handleChange} className="inputClass" />
								<TextField multiline rows={4} name="subHeadline" label="Sous titre"  value={this.state.data.subHeadline} onChange={this.handleChange} className="inputClass" />
								<TextField multiline rows={4} name="subHeadlineLocalite" label="Localité du sous titre"  value={this.state.data.subHeadlineLocalite} onChange={this.handleChange} className="inputClass" />
								<TextField name="email" type="email" label="Adresse email"  value={this.state.data.email} onChange={this.handleChange} className="inputClass" required/>
								<TextField name="phoneNumber" label="Numéro de téléphone"  value={this.state.data.phoneNumber} onChange={this.handleChange} className="inputClass" />
								<TextField name="streetAddress" label="Adresse postale"  value={this.state.data.streetAddress} onChange={this.handleChange} className="inputClass" />
								<TextField name="postalCode" label="Code Postal"  value={this.state.data.postalCode} onChange={this.handleChange} className="inputClass" />
								<TextField name="city" label="Ville"  value={this.state.data.city} onChange={this.handleChange} className="inputClass" />
								<Button  variant="contained" color="primary" onClick={this.handleSubmit} className='inputClass ' disabled={!this.state.data.email}>
									Publier
								</Button>
							</div>	
						</Card>
					);

	}
}